import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewMinerratePage = lazy(() => import('src/pages/dashboard/minerrate'));
const OverviewFoundersPage = lazy(() => import('src/pages/dashboard/founders'));
const OverviewAccounthistoryPage = lazy(() => import('src/pages/dashboard/accounthistory'));
const OverviewLitecoinHashratePage = lazy(() => import('src/pages/dashboard/litecoinhashrate'));
const OverviewLitecoinMinerratePage = lazy(() => import('src/pages/dashboard/litecoinminerrate'));
const OverviewAccountprofitPage = lazy(() => import('src/pages/dashboard/accountprofit'));
const OverviewPaymenthistoryPage = lazy(() => import('src/pages/dashboard/paymenthistory'));
const OverviewLitecoinAccountprofitPage = lazy(() => import('src/pages/dashboard/litecoinaccountprofit'));
const OverviewLitecoinPaymenthistoryPage = lazy(() => import('src/pages/dashboard/litecoinpaymenthistory'));
const OverviewVotingSystemPage = lazy(() => import('src/pages/dashboard/votingsystem'));
const OverviewStakingSystemPage = lazy(() => import('src/pages/dashboard/stakingsystem'));
const OverviewAdminSystemPage = lazy(() => import('src/pages/dashboard/adminsystem'));

const OverviewMinerCompoundingPage = lazy(() => import('src/pages/dashboard/minerCompounding'));
const OverviewRenegadeTreasuryPage = lazy(() => import('src/pages/dashboard/renegadeTreasury'));

// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// Motos
const MotosProfilePage = lazy(() => import('src/pages/dashboard/motos/profile'));
const MotosCardsPage = lazy(() => import('src/pages/dashboard/motos/cards'));
const MotosListPage = lazy(() => import('src/pages/dashboard/motos/list'));
const MotosAccountPage = lazy(() => import('src/pages/dashboard/motos/account'));
const MotosCreatePage = lazy(() => import('src/pages/dashboard/motos/new'));
const MotosEditPage = lazy(() => import('src/pages/dashboard/motos/edit'));
// Marcas
const MarcasProfilePage = lazy(() => import('src/pages/dashboard/marcas/profile'));
const MarcasCardsPage = lazy(() => import('src/pages/dashboard/marcas/cards'));
const MarcasListPage = lazy(() => import('src/pages/dashboard/marcas/list'));
const MarcasAccountPage = lazy(() => import('src/pages/dashboard/marcas/account'));
const MarcasCreatePage = lazy(() => import('src/pages/dashboard/marcas/new'));
const MarcasEditPage = lazy(() => import('src/pages/dashboard/marcas/edit'));
// Services
const ServicesProfilePage = lazy(() => import('src/pages/dashboard/services/profile'));
const ServicesCardsPage = lazy(() => import('src/pages/dashboard/services/cards'));
const ServicesListPage = lazy(() => import('src/pages/dashboard/services/list'));
const ServicesAccountPage = lazy(() => import('src/pages/dashboard/services/account'));
const ServicesCreatePage = lazy(() => import('src/pages/dashboard/services/new'));
const ServicesEditPage = lazy(() => import('src/pages/dashboard/services/edit'));
// Newsletter
const NewsletterProfilePage = lazy(() => import('src/pages/dashboard/newsletter/profile'));
const NewsletterCardsPage = lazy(() => import('src/pages/dashboard/newsletter/cards'));
const NewsletterListPage = lazy(() => import('src/pages/dashboard/newsletter/list'));
const NewsletterAccountPage = lazy(() => import('src/pages/dashboard/newsletter/account'));
const NewsletterCreatePage = lazy(() => import('src/pages/dashboard/newsletter/new'));
const NewsletterEditPage = lazy(() => import('src/pages/dashboard/newsletter/edit'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      { path: 'minerrate', element: <OverviewMinerratePage /> },
      { path: 'accounthistory', element: <OverviewAccounthistoryPage /> },
      { path: 'litecoinhashrate', element: <OverviewLitecoinHashratePage /> },
      { path: 'litecoinminerrate', element: <OverviewLitecoinMinerratePage /> },
      { path: 'accountprofit', element: <OverviewAccountprofitPage /> },
      { path: 'founders', element: <OverviewFoundersPage /> },
      { path: 'paymenthistory', element: <OverviewPaymenthistoryPage /> },
      { path: 'litecoinaccountprofit', element: <OverviewLitecoinAccountprofitPage /> },
      { path: 'litecoinpaymenthistory', element: <OverviewLitecoinPaymenthistoryPage /> },
      { path: 'votingsystem', element: <OverviewVotingSystemPage /> },
      { path: 'stakingsystem', element: <OverviewStakingSystemPage /> },
      { path: 'adminsystem', element: <OverviewAdminSystemPage /> },
      { path: 'minerCompounding', element: <OverviewMinerCompoundingPage /> },
      { path: 'renegadeTreasury', element: <OverviewRenegadeTreasuryPage /> },

      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'motos',
        children: [
          { element: <MotosProfilePage />, index: true },
          { path: 'profile', element: <MotosProfilePage /> },
          { path: 'cards', element: <MotosCardsPage /> },
          { path: 'list', element: <MotosListPage /> },
          { path: 'new', element: <MotosCreatePage /> },
          { path: ':id/edit', element: <MotosEditPage /> },
          { path: 'account', element: <MotosAccountPage /> },
        ],
      },
      {
        path: 'marcas',
        children: [
          { element: <MarcasProfilePage />, index: true },
          { path: 'profile', element: <MarcasProfilePage /> },
          { path: 'cards', element: <MarcasCardsPage /> },
          { path: 'list', element: <MarcasListPage /> },
          { path: 'new', element: <MarcasCreatePage /> },
          { path: ':id/edit', element: <MarcasEditPage /> },
          { path: 'account', element: <MarcasAccountPage /> },
        ],
      },
      {
        path: 'newsletter',
        children: [
          { element: <NewsletterProfilePage />, index: true },
          { path: 'profile', element: <NewsletterProfilePage /> },
          { path: 'cards', element: <NewsletterCardsPage /> },
          { path: 'list', element: <NewsletterListPage /> },
          { path: 'new', element: <NewsletterCreatePage /> },
          { path: ':id/edit', element: <NewsletterEditPage /> },
          { path: 'account', element: <NewsletterAccountPage /> },
        ],
      },
      {
        path: 'services',
        children: [
          { element: <ServicesProfilePage />, index: true },
          { path: 'profile', element: <ServicesProfilePage /> },
          { path: 'cards', element: <ServicesCardsPage /> },
          { path: 'list', element: <ServicesListPage /> },
          { path: 'new', element: <ServicesCreatePage /> },
          { path: ':id/edit', element: <ServicesEditPage /> },
          { path: 'account', element: <ServicesAccountPage /> },
        ],
      },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
        ],
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'post',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':title', element: <BlogPostPage /> },
          { path: ':title/edit', element: <BlogEditPostPage /> },
          { path: 'new', element: <BlogNewPostPage /> },
        ],
      },

      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      {
        path: 'tour',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
        ],
      },
      { path: 'file-manager', element: <FileManagerPage /> },
      { path: 'mail', element: <MailPage /> },
      { path: 'chat', element: <ChatPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
