import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import { bgGradient } from 'src/theme/css';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { useSettingsContext } from 'src/components/settings';

import Main from './main';
import Header from './header';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';


export default function DashboardLayout({ children }) {
  const settings = useSettingsContext();
  const lgUp = useResponsive('up', 'lg');
  const nav = useBoolean();
  const isHorizontal = settings.themeLayout === 'horizontal';
  
  const renderHorizontal = <NavHorizontal />;
  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  // Background settings
  const backgroundStyles = bgGradient({
    color: 'rgba(0, 0, 0, 0.85)',
    imgUrl: '/assets/background/dashboard_bg_1920x.png',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  });

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main sx={{ ...backgroundStyles }}>{children}</Main>
      </>
    );
  }

  

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
        }}
      >
        {renderNavVertical}

        <Main sx={{ ...backgroundStyles }}>{children}</Main>
      </Box>
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
