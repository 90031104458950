import { useMemo, useContext, useState } from 'react';
import { paths } from 'src/routes/paths';
import { useTranslate } from 'src/locales';
import { AdminContext } from 'src/context/AdminContext';
import { SiBitcoin, SiLitecoin } from 'react-icons/si';
import SvgColor from 'src/components/svg-color'; // Adjust the import path

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/iconsdash/${name}.png`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('Profit_Summary'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('Reward_History_BTC_and_LTC'),
  file: icon('Admin_1'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('Total_Hashrate'),
  label: icon('Individual_Hashrate'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  motorcycles: icon('ic_motorcycles'),
  treas: icon('treas'),
  treas2: icon('treas2'),
  services: icon('ic_services'),
  productstype: icon('ic_productstype'),
  newsletter: icon('ic_newsletter'),
  brands: icon('ic_brands'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('Voteing__Proposal'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('Staking_3'),
  dashboard: icon('Overview'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useTranslate();
  const { isAdmin } = useContext(AdminContext);

  const [selectedIcon, setSelectedIcon] = useState('bitcoin'); // Default to Bitcoin

  const toggleIcon = (iconName) => {
    setSelectedIcon(iconName); // Toggle between 'bitcoin' and 'litecoin'
  };

  const data = useMemo(() => {
    // Function to get the text color based on selected coin
    const getTextColor = () => selectedIcon === 'bitcoin' ? '#937f6c' : '#655353';

    const bitcoinItems = [
      {
        title: t('Total Hashrate'),
        path: paths.dashboard.accounthistory.root,
        icon: ICONS.order,
      },
      {
        title: t('Indiv Miner Hashrate'),
        path: paths.dashboard.minerrate.root,
        icon: ICONS.label,
      },
      {
        title: t('Profit Summary'),
        path: paths.dashboard.accountprofit.root,
        icon: ICONS.blog,
      },
      {
        title: t('BTC Reward History'),
        path: paths.dashboard.paymenthistory.root,
        icon: ICONS.user,
      },
    ];


    const litecoinItems = [
      {
        title: t('Total Hashrate'),
        path: paths.dashboard.litecoinhashrate.root,
        icon: ICONS.order,
      },
      {
        title: t('Indiv Miner Hashrate'),
        path: paths.dashboard.litecoinminerrate.root,
        icon: ICONS.label,
      },
      {
        title: t('Profit Summary'),
        path: paths.dashboard.litecoinaccountprofit.root,
        icon: ICONS.blog,
      },
      {
        title: t('LTC & Doge  Reward History'),
        path: paths.dashboard.litecoinpaymenthistory.root,
        icon: ICONS.user,
      },
    ];

    // Combine the Bitcoin and Litecoin items conditionally based on selectedIcon
    const selectedItems = selectedIcon === 'bitcoin' ? bitcoinItems : litecoinItems;

    return [
      {
        subheader: (
          <span style={{ color: '#d1ba8e', cursor: 'default' }}>
            {t('Renegade Hall')}
          </span>
        ),
        items: [
          {
            title: <span style={{ color: '#d1ba8e' }}>{t('Overview')}</span>,
            path: paths.dashboard.general.ecommerce,
            icon: ICONS.dashboard,
          },
          {
            title: <span style={{ color: '#d1ba8e' }}>{t('Staking Dashboard')}</span>,
            path: paths.dashboard.stakingsystem.root,
            icon: ICONS.analytics,
          },
          {
            title: <span style={{ color: '#d1ba8e' }}>{t('Voting & Proposals')}</span>,
            path: paths.dashboard.votingsystem.root,
            icon: ICONS.external,
          },
          isAdmin
            ? {
                title: <span style={{ color: '#d1ba8e' }}>{t('Admin System')}</span>,
                path: paths.dashboard.adminsystem.root,
                icon: ICONS.file,
              }
            : null,
        ].filter(Boolean),
      },
      {
        subheader: (
          <span style={{ color: '#d1ba8e', cursor: 'default' }}>
            {t('Treasuries')}
          </span>
        ),
        items: [
          {
            title: <span style={{ color: '#d1ba8e' }}>{t('Miner Compounding')}</span>,
            path: paths.dashboard.minerCompounding.root, 
            icon: ICONS.treas2,
          },
          {
            title: <span style={{ color: '#d1ba8e' }}>{t('Renegade Treasury')}</span>,
            path: paths.dashboard.renegadeTreasury.root,
            icon: ICONS.treas,
          },
        ],
      },
      {
        subheader: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '16px',
              width: '100%',
            }}
          >
            <button
              type="button"
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                gap: '8px',
                marginBottom: '15px',
                background: 'transparent',
                border: '3px solid #cdb68c',
                borderRadius: '50%',
                padding: 0,
              }}
              onClick={() => toggleIcon('bitcoin')}
              aria-label="Select Bitcoin"
            >
              <SiBitcoin size={46} color="#937f6c" style={{ fill: '#937f6c' }} />
            </button>
            <button
              type="button"
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                gap: '8px',
                marginBottom: '15px',
                background: 'transparent',
                border: '3px solid #cdb68c',
                borderRadius: '50%',
                padding: 0,
              }}
              onClick={() => toggleIcon('litecoin')}
              aria-label="Select Litecoin"
            >
              <img
                src="https://i.imgur.com/sCPURII.png"
                alt="Litecoin"
                width="46"
                height="46"
              />
            </button>
          </div>
        ),
        items: selectedItems.map((item) => ({
          ...item,
          title: <span style={{ color: getTextColor() }}>{item.title}</span>,
        })),
      },
    ];
    }, [t, isAdmin, selectedIcon]);
    
    return data;
  }
