import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Hedera = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {

  const hedera = (
    <Box
      component="img"
      alt="hedera"
      src="/logo/hedera.png"
      sx={{
        width: 169,
        height: 80,
        marginLeft: '30px',
        marginTop: '15px',
        display: { xs: 'none', md: 'inline-flex' }, // Hide on mobile, show on desktop
        ...sx,
      }}
    />
  );

  if (disabledLink) {
    return hedera;
  }

  return (
    <Link component={RouterLink} sx={{ display: 'contents' }}>
      {hedera}
    </Link>
  );
});

Hedera.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Hedera;
