import axios from "axios";
import bcrypt from 'bcryptjs';
import { HashConnect } from "hashconnect";
import { NftId, TokenId, LedgerId, AccountId, AccountAllowanceDeleteTransaction, AccountAllowanceApproveTransaction, TransferTransaction } from "@hashgraph/sdk";

// Define constants
const network = 'mainnet';
const API_URL = 'https://www.uturenegades.com/';

const FIXED_SALT = import.meta.env.VITE_FIXED_SALT;

const appMetadata = {
  name: "Royal Labs",
  description: "Royal Labs",
  icons: ["https://mydapp.com/logo.png"],
  url: "https://www.uturenegades.com/"
};

let hashconnect = null;
let state = "Disconnected";
let pairingData;

hashconnect = new HashConnect(LedgerId.MAINNET, "fed0c3213b34c5a0e4290415b4fd3e5c", appMetadata, true);

hashconnect.init();

const showPasswordModal = () => new Promise((resolve, reject) => {
  // Create modal HTML
  const modalHTML = `
    <div id="passwordModal" class="modal">
      <div class="modal-content">
        <span class="close">&times;</span>
        <p>Enter admin password:</p>
        <input type="password" id="adminPassword" />
        <button id="submitPassword">Submit</button>
      </div>
    </div>
  `;
  
  // Add modal HTML to the document body
  const modalDiv = document.createElement('div');
  modalDiv.innerHTML = modalHTML;
  document.body.appendChild(modalDiv);

  // Add modal CSS
  const modalCSS = `
    #passwordModal.modal {
      display: block;
      position: fixed;
      z-index: 1000;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      background-color: rgba(0,0,0,0.9);
      padding-top: 60px;
    }

    #passwordModal .modal-content {
      background-color: #000;
      margin: 5% auto;
      padding: 20px;
      border: 1px solid #888;
      width: 40%; /* Adjust as needed */
      color: #fff;
    }

    #passwordModal .close {
      color: #fff;
      float: right;
      font-size: 28px;
      font-weight: bold;
    }

    #passwordModal .close:hover,
    #passwordModal .close:focus {
      color: #bbb;
      text-decoration: none;
      cursor: pointer;
    }

    #passwordModal p {
      color: #fff;
    }

    #passwordModal input[type="password"] {
      width: 100%;
      padding: 12px 20px;
      margin: 8px 0;
      display: inline-block;
      border: 1px solid #ccc;
      box-sizing: border-box;
      background-color: #333;
      color: #fff;
    }

    #passwordModal button {
      background-color:rgb(179, 159, 122);
      color: white;
      padding: 14px 20px;
      margin: 8px 0;
      border: none;
      cursor: pointer;
      width: 100%;
    }

    #passwordModal button:hover {
      opacity: 0.8;
    }
  `;
  
  const styleElement = document.createElement('style');
  styleElement.innerHTML = modalCSS;
  document.head.appendChild(styleElement);

  const modal = document.getElementById("passwordModal");
  const span = modalDiv.querySelector(".close");
  const submitButton = document.getElementById("submitPassword");

  const closeModal = () => {
    modal.style.display = "none";
    document.body.removeChild(modalDiv); // Remove modal from DOM
  };

  span.onclick = () => {
    closeModal();
    reject(new Error('Admin password is required'));
    // Clear storage and reload the page on close button click
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };

  window.onclick = event => {
    if (event.target === modal) {
      closeModal();
      reject(new Error('Admin password is required'));
      // Clear storage and reload the page on modal background click
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
  };

  submitButton.onclick = async () => {
    const adminPassword = document.getElementById("adminPassword").value;
    if (!adminPassword.trim()) {
      alert("Password is required");
      return;
    }

    // Hash the password using the fixed salt
    const hashedPassword = bcrypt.hashSync(adminPassword, FIXED_SALT);

    closeModal();
    resolve(hashedPassword);
  };
});

export const connectWalletHashPack = async (setAdmin) => {
  hashconnect.init();
  setUpHashConnectEvents();

  hashconnect.openPairingModal();

  return new Promise((resolve, reject) => {
    hashconnect.pairingEvent.on(async (newPairing) => {
      pairingData = newPairing;
      if (pairingData && pairingData.accountIds && pairingData.accountIds.length > 0) {
        const accountId = pairingData.accountIds[0];

        localStorage.setItem('account_id', accountId);

        try {
          const response = await axios.post(`${API_URL}api/connect-wallet`, { account_id: accountId });
          setAdmin(response.data.isAdmin);

          if (response.data.isAdmin) {
            try {
              const hashedPassword = await showPasswordModal();
              const passwordResponse = await axios.post(`${API_URL}api/adminPasswordAuth`, { account_id: accountId, password: hashedPassword });
              if (!passwordResponse.data.auth) {
                alert('Incorrect admin password');
                reject(new Error('Password is incorrect'));
                window.location.reload(); // Reload page on incorrect password
                return;
              }

              localStorage.setItem('access_token', passwordResponse.data.token);

              // Call checkid route
              const checkIdResponse = await axios.post(`${API_URL}api/checkid`, { account_id: accountId });
              resolve({
                account_id: accountId,
                access_token: passwordResponse.data.token,
                permission: true,
                checkid_response: checkIdResponse.data,
              });
            } catch (error) {
              console.error('Error validating admin password:', error);
              reject(new Error('Error validating admin password'));
            }
          } else if (response.data.message === 'User already Connected') {
            resolve({
              account_id: accountId,
              access_token: window.localStorage.getItem('access_token'),
              permission: response.data.isAdmin,
            });
          } else {
            localStorage.setItem('access_token', response.data.token);
            localStorage.setItem('account_id', accountId);

            // Call checkid route automatically after connecting wallet
            const checkIdResponse = await axios.post(`${API_URL}api/checkid`, { account_id: accountId });
            resolve({
              account_id: accountId,
              access_token: response.data.token,
              checkid_response: checkIdResponse.data,
            });
          }
        } catch (error) {
          console.error('Error connecting wallet:', error);
          reject(new Error('Error connecting wallet'));
        }
      } else {
        reject(new Error('Pairing failed or no accounts found.'));
      }
    });

    hashconnect.disconnectionEvent.on(() => {
      pairingData = null;
    });

    hashconnect.connectionStatusChangeEvent.on(connectionStatus => {
      state = connectionStatus;
    });
  });
};





function setUpHashConnectEvents() {
  if (hashconnect) {
    hashconnect.pairingEvent.on((newPairing) => {
      pairingData = newPairing;
      if (pairingData && pairingData.accountIds && pairingData.accountIds.length > 0) {
        const accountId = pairingData.accountIds[0];

        // Store account_id and topic in localStorage
        localStorage.setItem("account_id", accountId);
      }
    });

    hashconnect.connectionStatusChangeEvent.on((connectionStatus) => {
      state = connectionStatus;
    });
  } else {
    console.error("HashConnect is not initialized.");
  }
}

export const clearDB = async () => {
  try {
    // Open the IndexedDB database
    const db = await window.indexedDB.open('WALLET_CONNECT_V2_INDEXED_DB', 1);

    // Handle the success case
    db.onsuccess = () => {
      // Delete the database
      const deleteDBRequest = window.indexedDB.deleteDatabase('WALLET_CONNECT_V2_INDEXED_DB');
      
      // Handle the error case
      deleteDBRequest.onerror = (event) => {
        console.error('Error deleting IndexedDB database:', event.target.error);
      };
    };

    // Handle the error case
    db.onerror = (event) => {
      console.error('Error opening IndexedDB database:', event.target.error);
    };
  } catch (error) {
    console.error('Error clearing IndexedDB:', error);
  }
};


const retryApiCall = async (apiCall, selectedNFTs, tokenId, delay = 1500) => {
  try {
    const response = await apiCall(selectedNFTs, tokenId);
    const allSuccess = response.every(item => item.success);

    if (!allSuccess) {
      await new Promise(resolve => setTimeout(resolve, delay));
      return retryApiCall(apiCall, selectedNFTs, tokenId, delay); // Recursive call
    }
    return response;
  } catch (error) {
    console.error("Error in API call retry:", error);
    await new Promise(resolve => setTimeout(resolve, delay));
    return retryApiCall(apiCall, selectedNFTs, tokenId, delay); // Recursive call
  }
};

export const sendAndProcessAllowanceNFT = async (selectedNFTs, tokenId) => {
  try {
    const success = await sendAllowanceNFT(selectedNFTs, tokenId);
    if (success) {
      setTimeout(async () => {
        await retryApiCall(sendNAllowanceNFTAPI, selectedNFTs, tokenId);
      }, 1500); // 1.5-second delay
    }
    return success;
  } catch (error) {
    console.error("Error processing allowance NFT:", error);
    return false;
  }
};

export const removeAndProcessAllowanceNFT = async (selectedNFTs, tokenId) => {
  try {
    const success = await removeAllowanceNFT(selectedNFTs, tokenId);
    if (success) {
      setTimeout(async () => {
        await retryApiCall(removeAllowanceNFTAPI, selectedNFTs, tokenId);
      }, 1500); // 1.5-second delay
    }
    return success;
  } catch (error) {
    console.error("Error processing allowance NFT:", error);
    return false;
  }
};

const sendNAllowanceNFTAPI = async (selectedNFTs, tokenId) => {
  try {
    const senderAccountId = localStorage.getItem('account_id');
    const apiUrlBase = 'https://www.uturenegades.com/api/nfts/stake/';
    const accessToken = localStorage.getItem('access_token');

    const serial_numbers = selectedNFTs.map(nft => nft.serialNumber);

    const apiUrl = `${apiUrlBase}${senderAccountId}`;
    const response = await axios.patch(apiUrl, { serial_numbers }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error staking NFTs:", error);
    return [{ success: false }];
  }
};

const removeAllowanceNFTAPI = async (selectedNFTs, tokenId) => {
  try {
    const senderAccountId = localStorage.getItem('account_id');
    const apiUrlBase = 'https://www.uturenegades.com/api/nfts/unstake/';
    const accessToken = localStorage.getItem('access_token');

    const serial_numbers = selectedNFTs.map(nft => nft.serialNumber);

    const apiUrl = `${apiUrlBase}${senderAccountId}`;
    const response = await axios.patch(apiUrl, { serial_numbers }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    });


    return response.data;
  } catch (error) {
    console.error("Error removing allowance NFT:", error);
    return [{ success: false }];
  }
};

const sendAllowanceNFT = async (selectedNFTs, tokenId) => {
  try {
      const senderAccountId = localStorage.getItem('account_id');
      const receiverAccountId = "0.0.3716410";

      const signer = hashconnect.getSigner(AccountId.fromString(senderAccountId));
      const uniqueSerialNumbers = new Set(selectedNFTs.map(nft => nft.serialNumber));
      const transaction = new AccountAllowanceApproveTransaction();

      uniqueSerialNumbers.forEach(serial => {
          const nftId = new NftId(TokenId.fromString(tokenId), serial);
          transaction.approveTokenNftAllowance(nftId, AccountId.fromString(senderAccountId), AccountId.fromString(receiverAccountId));
      });

      const frozenTransaction = await transaction.freezeWithSigner(signer);
      const result = await frozenTransaction.executeWithSigner(signer);

      return true;
  } catch (error) {
      if (error.message.includes("Proposal expired")) {
          console.error("Proposal expired. Please try again.");
      } else {
          console.error("Error creating NFT allowance:", error);
      }
      return false;
  }
};

const removeAllowanceNFT = async (selectedNFTs, tokenId) => {
  try {
      const senderAccountId = localStorage.getItem('account_id');

      const signer = hashconnect.getSigner(AccountId.fromString(senderAccountId));
      const uniqueSerialNumbers = new Set(selectedNFTs.map(nft => nft.serialNumber));
      const transaction = new AccountAllowanceDeleteTransaction();

      uniqueSerialNumbers.forEach(serial => {
          const nftId = new NftId(TokenId.fromString(tokenId), serial);
          transaction.deleteAllTokenNftAllowances(nftId, AccountId.fromString(senderAccountId));
      });

      const frozenTransaction = await transaction.freezeWithSigner(signer);
      const result = await frozenTransaction.executeWithSigner(signer);

      return true;
  } catch (error) {
      if (error.message.includes("Proposal expired")) {
          console.error("Proposal expired. Please try again.");
      } else {
          console.error("Error removing NFT allowance:", error);
      }
      return false;
  }
};

export const sendDepositHbar = async (depositAmount) => {
  try {
      const REACT_APP_ACCOUNT_ID = "0.0.3716410";
      const senderAccountId = localStorage.getItem('account_id');
      const receiverAccountId = REACT_APP_ACCOUNT_ID;

      // Check if senderAccountId is null or undefined
      if (!senderAccountId) {
          console.error("Sender account ID is not available.");
          return false;
      }


      const signer = hashconnect.getSigner(AccountId.fromString(senderAccountId));

      const trans = await new TransferTransaction()
          .addHbarTransfer(AccountId.fromString(senderAccountId), -depositAmount)
          .addHbarTransfer(AccountId.fromString(receiverAccountId), depositAmount)
          .freezeWithSigner(signer);  

      const result = await trans.executeWithSigner(signer);
      return result;
  } catch (error) {
      console.error("Error sending HBAR deposit:", error);
      return false;
  }
};
